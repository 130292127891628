import { User } from "@legacy/models/User";
import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import {
    FEATURE_BLOCK_DRIVERS_MANAGEMENT_ACCESS,
    FEATURE_BLOCK_DRIVERS_MANAGEMENT_ACCESS_IN_COUNTRIES,
} from "../../config.management";
import type { RoutingStore } from "../../container";
import { stores, container } from "../../container";
import type { AuthenticationStore } from "../../domain/authentication/AuthenticationStore";
import { getAuthenticationStore } from "../../domain/authentication/AuthenticationStore.singleton";
import { getRpcClient } from "../../rpc-browser-sdk";

@autobind
export class LayoutStore {
    public routingStore = container.get<RoutingStore>(stores.routing);

    public authenticationStore: AuthenticationStore;

    public constructor() {
        this.authenticationStore = getAuthenticationStore();
    }

    @observable
    public isMobileMenuOpened: boolean = false;

    @observable
    public isDriverAccessBlocked: boolean = false;

    @observable
    public isLoading: boolean = true;

    @action
    public toggleMobileMenu(): void {
        this.isMobileMenuOpened = !this.isMobileMenuOpened;
    }

    @action
    public async fetchData(): Promise<void> {
        try {
            this.isDriverAccessBlocked = await this.checkDriverAccessBlocked();
        } finally {
            this.isLoading = false;
        }
    }

    @action
    private async checkDriverAccessBlocked(): Promise<boolean> {
        const { isTransporter, isDriversCompany, userJWT } = this.authenticationStore;
        const blockedCountryIds = FEATURE_BLOCK_DRIVERS_MANAGEMENT_ACCESS_IN_COUNTRIES.split(",")
            .map((country) => country.trim())
            .filter((country) => country !== "");

        let isBlocked = false;

        if (isDriversCompany) {
            return false;
        }

        if (isTransporter && userJWT && FEATURE_BLOCK_DRIVERS_MANAGEMENT_ACCESS) {
            if (!blockedCountryIds?.length) {
                isBlocked = true;
            } else {
                const loggedInUser: User = await getRpcClient().user.retrieveUser(userJWT.userId);
                isBlocked = blockedCountryIds.includes(loggedInUser.countryId);
            }
        }

        return isBlocked;
    }
}
