import { PenaltyCategories, PenaltyCategory, PenaltySubcategories, PenaltySubcategory } from "@daytrip/legacy-enums";
import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose, Transform } from "class-transformer";
import { IsBoolean, IsDefined, IsEnum, IsInt, IsOptional, IsString, IsUUID, ValidateIf } from "class-validator";
import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";

import { Currency } from "../domain/Currency";
import { CDBaseEntity, CDColumn, CDCreateDateColumn, CDEntity, CDObjectIdColumn } from "../orm";

@CDEntity("penalties")
export class Penalty extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    @Transform((value) => (value >= 0 ? value : 0))
    public value: number;

    @CDColumn()
    @IsString()
    @IsOptional()
    @Expose()
    public managementNote?: string;

    @CDColumn()
    @IsBoolean()
    @Expose()
    public shouldAffectRating: boolean = true;

    @CDColumn()
    @ValidateIf((p: Penalty) => p.category === PenaltyCategory.OTHER || p.subcategory === PenaltySubcategory.OTHER)
    @IsString()
    @IsDefined()
    @Expose()
    public description?: string;

    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    public category: PenaltyCategory;

    @CDColumn()
    @ValidateIf((p: Penalty) => !isEmpty(PenaltySubcategories[p.category]))
    @IsString()
    @IsDefined()
    @Expose()
    public subcategory: PenaltySubcategory;

    @CDColumn()
    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public currency = Currency.Euro;

    @CDCreateDateColumn()
    @Expose()
    @TransformToDate
    public createdAt: Date = new Date();

    public get displayDescription(): string {
        return [
            PenaltyCategories[this.category],
            PenaltySubcategories[this.category]?.[this.subcategory],
            this.description,
        ]
            .filter(Boolean)
            .join(" / ");
    }
}
