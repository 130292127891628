import { SubsidyCategories, SubsidyCategory, SubsidySubcategories, SubsidySubcategory } from "@daytrip/legacy-enums";
import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose } from "class-transformer";
import { IsDefined, IsEnum, IsInt, IsString, IsUUID, IsOptional, ValidateIf } from "class-validator";
import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";

import { Currency } from "../domain/Currency";
import { CDBaseEntity, CDColumn, CDCreateDateColumn, CDEntity, CDObjectIdColumn } from "../orm";

@CDEntity("subsidies")
export class Subsidy extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    public value: number;

    @CDColumn()
    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public currency = Currency.Euro;

    @CDColumn()
    @IsString()
    @IsOptional()
    @Expose()
    public managementNote?: string;

    @CDColumn()
    @ValidateIf((s: Subsidy) => s.category === SubsidyCategory.OTHER || s.subcategory === SubsidySubcategory.OTHER)
    @IsString()
    @IsDefined()
    @Expose()
    public description?: string;

    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    public category: SubsidyCategory;

    @CDColumn()
    @ValidateIf((s: Subsidy) => !isEmpty(SubsidySubcategories[s.category]))
    @IsString()
    @IsDefined()
    @Expose()
    public subcategory: SubsidySubcategory;

    @CDCreateDateColumn()
    @Expose()
    @TransformToDate
    public createdAt: Date = new Date();

    public get displayDescription(): string {
        return [
            SubsidyCategories[this.category],
            SubsidySubcategories[this.category]?.[this.subcategory],
            this.description,
        ]
            .filter(Boolean)
            .join(" / ");
    }
}
