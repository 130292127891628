import {
    CompensationCategories,
    CompensationCategory,
    CompensationSubcategories,
    CompensationSubcategory,
} from "@daytrip/legacy-enums";
import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose, Transform } from "class-transformer";
import { IsDefined, IsEnum, IsInt, IsOptional, IsString, IsUUID, ValidateIf } from "class-validator";
import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";

import { Currency } from "../domain/Currency";
import { CDBaseEntity, CDColumn, CDCreateDateColumn, CDEntity, CDObjectIdColumn } from "../orm";

@CDEntity("compensations")
export class Compensation extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    @Transform((value) => (value >= 0 ? value : 0))
    public value: number;

    @CDColumn()
    @IsString()
    @IsOptional()
    @Expose()
    public managementNote?: string;

    @CDColumn()
    @ValidateIf(
        (c: Compensation) =>
            c.category === CompensationCategory.OTHER || c.subcategory === CompensationSubcategory.OTHER,
    )
    @IsString()
    @IsDefined()
    @Expose()
    public description?: string;

    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    public category: CompensationCategory;

    @CDColumn()
    @ValidateIf((c: Compensation) => !isEmpty(CompensationSubcategories[c.category]))
    @IsString()
    @IsDefined()
    @Expose()
    public subcategory: CompensationSubcategory;

    @CDCreateDateColumn()
    @Expose()
    @TransformToDate
    public createdAt: Date = new Date();

    @CDColumn()
    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public currency = Currency.Euro;

    public get displayDescription(): string {
        return [
            CompensationCategories[this.category],
            CompensationSubcategories[this.category]?.[this.subcategory],
            this.description,
        ]
            .filter(Boolean)
            .join(" / ");
    }
}
