import React from "react";

import type { ToastOptions } from "react-toastify";
import { toast } from "react-toastify";

import styles from "./notificationToasts.sass";

const defaultOptions: ToastOptions = {
    autoClose: 3000,
    hideProgressBar: false,
    position: "top-right",
    pauseOnHover: true,
};

const ToastBody = (title: string, description?: string) => {
    return (
        <div className={styles.toastBody}>
            <div className={styles.toastTitle}>{title}</div>
            <div className={styles.toastDescription} dangerouslySetInnerHTML={{ __html: description || "" }}></div>
        </div>
    );
};

export function toastSuccess(title: string, description?: string, options?: ToastOptions) {
    toast.success(ToastBody(title, description), { ...(options || defaultOptions) });
}

export function toastWarning(title: string, description?: string, options?: ToastOptions) {
    toast.warning(ToastBody(title, description), options || defaultOptions);
}

export function toastError(title: string, description?: string, options?: ToastOptions) {
    toast.error(ToastBody(title, description), options || defaultOptions);
}

export function toastQuickSuccess(title: string, description?: string, options?: ToastOptions) {
    toast.success(ToastBody(title, description), {
        ...(options || defaultOptions),
        autoClose: 400,
        hideProgressBar: true,
        theme: "colored",
    });
}
